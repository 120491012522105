import APIService from '@/services/APIService.js'

export default {
  getServicesRequests () {
    return APIService.apiCall().get('/service-requests.json')
  },
  getRecentServicesRequests () {
    return APIService.apiCall().get('/service-requests/recent.json')
  },
  postServiceRequest (dataRequest,idRequest=null) {
    const postData = { ...dataRequest }
    if(idRequest) {
      return APIService.apiCall().post('/service-requests/edit/'+idRequest+'.json',JSON.stringify(postData))
    }
    return APIService.apiCallUnauthourized().post('/service-requests/request.json',JSON.stringify(postData))
  },
  getServiceRequest (id) {
    return APIService.apiCall().get('/service-requests/view/'+id+'.json')
  },
  deleteServiceRequest(id) {
    return APIService.apiCall().delete('/service-requests/delete/'+id+'.json')
  },
  track(code) {
    return APIService.apiCall().get('/service-requests/track/'+code+'.json')
  }
}
