<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Solicitação"
          class="px-5 py-3"
        >

          <v-subheader class="display-1 mt-3">
            Ordem e Status:
          </v-subheader>

          <v-select
            v-model="order_id"
            label="Nº da Ordem"
            item-text="id"
            item-value="id"
            :items="ordersList"
            class="ml-4"
            outlined
          />

          <v-select
            v-model="status"
            label="Status"
            :items="statusOptions"
            class="ml-4"
            outlined
          />


          <v-subheader class="display-1 mt-3">
            Dados Equipamento:
          </v-subheader>

          <v-form class="pt-1" @submit.prevent="postRequest">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Modelo do Equipamento"
            >
              <v-text-field
                label="Modelo do Equipamento"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="productmodel"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Failure"
            >

              <v-textarea
                name="input-7-1"
                label="Defeito Apresentado"
                value=""
                hint="Escreva o que você precisa"
                class="ml-4"
                :error-messages="errors"
                v-model="failure"
              />

            </validation-provider>

            <v-subheader class="display-1 mt-3">
              Dados Pessoais:
            </v-subheader>

            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nome"
                >

                  <v-text-field
                    label="Nome"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="name"
                  />

                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="CPF/CNPJ"
                >

                  <v-text-field
                    label="CPF/CNPJ"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="cpf"
                  />

                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Whatsapp"
                >

                  <v-text-field
                    label="Whatsapp"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="whatsapp"
                    v-mask="'(##)#-####-####'"
                  />

                </validation-provider>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Telefone"
                  outlined
                  class="ml-4"
                  v-model="tel"
                  v-mask="'(##)####-####'"
                />

              </v-col>
            </v-row>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Email"
            >

              <v-text-field
                label="Email"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="email"
              />

            </validation-provider>

            <v-subheader class="display-1 mt-3">
              Endereço:
            </v-subheader>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="CEP"
            >

              <v-text-field
                label="CEP"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="cep"
                @keyup="searchCep()"
              />

            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Endereço"
            >

              <v-text-field
                label="Endereço"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="address"
              />

            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Complemento"
            >

              <v-text-field
                label="Complemento"
                outlined
                class="ml-4"
                :error-messages="errors"
                v-model="complement"
              />

            </validation-provider>

            <v-row>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Cidade"
                >
                  <v-text-field
                    label="Cidade"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="city"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Bairro"
                >
                  <v-text-field
                    label="Bairro"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="district"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Estado"
                >
                  <v-text-field
                    label="Estado"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="state"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-textarea
                name="input-7-1"
                label="Observação"
                value=""
                hint="observações"
                class="ml-4"
                v-model="description"
              />
            </v-row>


            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import OrdersService from '@/services/OrdersService'
  import axios from 'axios'

  export default {
    name: 'Request',

    components: { 

    },

    data: () => ({
      request_id: "",
      name: "",
      description: "",
      whatsapp: "",
      email: "",
      tel: "",
      productmodel: "",
      failure: "",
      cpf: "",
      cep: "",
      address: "",
      complement: "",
      city: "",
      district: "",
      state: "",
      order_id: "",
      statusOptions: ['SOLICITADO', 'ANALISE', 'ORDEM CRIADA', 'CONCLUIDO'],
      ordersList: [],
      status: "",
    }),

    computed: {
      
    },

    mounted () {
      this.getRequest(this.$route.params.id)
      this.getOrdersList()
    },

    methods: {

      getOrdersList(id) {
        OrdersService.getOrders()
        .then(response => {
          this.ordersList = []
          let obj = {}
          response.data.orders.forEach(element => {
            obj = {
              id: element.id,
              title: element.title,
            }
            this.ordersList.push(obj);
          });
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar ordens, recarregue a página.', '',{position:'topRight'})
        })
      },

      getRequest(id) {
        ServiceRequestsService.getServiceRequest(id)
        .then(response => {

          this.request_id = response.data.service_request.id
          this.name = response.data.service_request.name
          this.description = response.data.service_request.description
          this.whatsapp = response.data.service_request.whatsapp
          this.email = response.data.service_request.email
          this.tel = response.data.service_request.tel
          this.productmodel = response.data.service_request.productmodel
          this.failure = response.data.service_request.failure
          this.cpf = response.data.service_request.cpf
          this.cep = response.data.service_request.cep
          this.address = response.data.service_request.address
          this.complement = response.data.service_request.complement
          this.city = response.data.service_request.city
          this.district = response.data.service_request.district
          this.state = response.data.service_request.state
          this.status = response.data.service_request.status
          this.order_id = response.data.service_request.order_id
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar requisição.', '',{position:'topRight'})
        })
      },

      postRequest() {
        let data = []

        data['name'] = this.name
        data['description'] =  this.description
        data['whatsapp'] =  this.whatsapp
        data['email'] =  this.email
        data['tel'] =  this.tel
        data['productmodel'] =  this.productmodel
        data['failure'] =  this.failure
        data['cpf'] =  this.cpf
        data['cep'] =  this.cep
        data['address'] =  this.address
        data['complement'] =  this.complement
        data['city'] =  this.city
        data['district'] =  this.district
        data['state'] =  this.state
        data['status'] = this.status
        data['order_id'] = this.order_id

        ServiceRequestsService.postServiceRequest(data,this.request_id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Error"){
            throw 500
          }
          this.$toast.success('Solicitação Salva.', '',{position:'topRight'})
          this.$router.push({ name: 'Requisições de Serviço' })
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao enviar solicitação cheque os campos.', '',{position:'topRight'})
        })
      },

      searchCep () {
        if(this.cep.length == 8) {
          axios.get(`https://viacep.com.br/ws/${ this.cep }/json/`)
          .then((response) => {
            this.address = response.data.logradouro
            this.city = response.data.localidade
            this.district = response.data.bairro
            this.state = response.data.uf
          })
          .catch( error => console.log(error) )
        }
      },

    }
  }
</script>